import React from "react";
import { Button, Row, Col, Spinner, Container } from "reactstrap";
import PurchaseRecordModal from "../../../Components/modal/PurchaseRecordModal";
import ReservationGiftModal from "../../../Components/modal/ReservationGiftModal";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { API, BACKEND_HOST } from "../../../api";
import ReservationCheckoutModal from "../../../Components/modal/ReservationCheckoutModal";
import { FiCalendar } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa6";
import { IoGiftOutline } from "react-icons/io5";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import QueryString from "qs";
import { getAccessToken } from "../../../api/auth";
import Navbar from "../../../Components/Common/navbar";
import { useInfiniteQuery } from "react-query";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  /* max-width: 480px; */
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const FilterBadge = styled.div`
  border-radius: 100px;
  padding: 0.4rem 0.8rem;
  user-select: none;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#f6475f" : "#e9ecef")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  font-family: "Gmarket-M";
  font-size: 12px;
`;

function ReservationList({ todayOnly }) {
  const navigate = useNavigate();

  const [purchaseRecordModal, setPurchaseRecordModal] = React.useState(false);
  const [giftModal, setGiftModal] = React.useState(false);
  const [selectOrder, setSelectOrder] = React.useState({});
  const [isCancel, setIsCancel] = React.useState(false);
  const [checkoutModal, setCheckoutModal] = React.useState(false);

  const [queryString, setQueryString] = React.useState({
    filter_site: SUBDOMAIN,
    filter_states: "예약,입장,외출,퇴장",
  });

  const setdata = async (page) => {
    const accessToken = getAccessToken();

    let _queryString = QueryString.stringify(queryString);
    if (_queryString) _queryString = `&${_queryString}`;
    else _queryString = "";

    const response = await API.get(`/package/user-order-app/?page=${page}${_queryString}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;
    return data;
  };


  const {
    status, data, error, isSuccess, isFetching, isLoading, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, refetch
  } = useInfiniteQuery(["reservation-list", queryString], ({ pageParam = 1 }) => setdata(pageParam), {
    getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
  });


  const handelCancelGift = (reservId) => {
    if (window.confirm("예약상품의 선물을 취소하시겠습니까?")) {
      const accessToken = getAccessToken();
      API.delete(`/package/cancel-reservation-gift/${reservId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          const data = response.data;
          console.log(data);
          navigate(0);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>
            <Row className="mt-4">

              {/* filter badges */}
              <div className="d-flex gap-2" style={{ paddingLeft: "30px", marginBottom: "20px" }}>
                <FilterBadge
                  active={queryString.filter_states === ""}
                  onClick={() => {
                    setQueryString({ ...queryString, filter_states: "" });
                  }}
                >
                  전체
                </FilterBadge>
                <FilterBadge
                  active={queryString.filter_states === "예약,입장,외출,퇴장"}
                  onClick={() => {
                    setQueryString({ ...queryString, filter_states: "예약,입장,외출,퇴장" });
                  }}
                >
                  사용가능
                </FilterBadge>
                <FilterBadge
                  active={queryString.filter_states === "완료,노쇼"}
                  onClick={() => {
                    setQueryString({ ...queryString, filter_states: "완료,노쇼" });
                  }}
                >
                  완료
                </FilterBadge>
              </div>

              <Col className="col-12 d-flex flex-wrap justify-content-center gap-4" style={{}}>
                {data && isSuccess ? (
                  <>
                    {data?.pages.map((page, index) => (
                      <React.Fragment key={index}>
                        {page.results.map((order, index) => (

                          <Col lg={12} xxl={12} md={12} sm={12} xs={12} key={order.id} className="">
                            <Item visible={true} className="bg-white w-md-480">
                              <div
                                className="d-flex justify-content-between px-2 border-bottom"
                                onClick={() => {
                                  if (!order.gifted && !["입장", "퇴장", "외출", "완료", "노쇼"].includes(order.state)) {
                                    setIsCancel(false);
                                    setSelectOrder(order);
                                    setPurchaseRecordModal(!purchaseRecordModal);
                                  }
                                  // else if (!order.gifted && ["입장"].includes(order.state)) {
                                  //   setIsCancel(false);
                                  //   setSelectOrder(order);
                                  //   setCheckoutModal(true);
                                  // }
                                }}
                              >
                                <div className="card-title pt-3" style={{ fontWeight: "600" }}>
                                  {order.name}
                                </div>
                                {!order.gifted && !["입장", "퇴장", "외출", "완료", "노쇼"].includes(order.state) ? (
                                  <div className="fw-bold mt-2 btn btn-primary">{order.depositRequired ? "체크인/청소보증금" : "체크인"}</div>
                                ) : null}
                                {!order.gifted && ["입장"].includes(order.state) ? <p className="text-muted pt-3">입장</p> : null}
                                {!order.gifted && ["퇴장"].includes(order.state) ? <p className="text-muted pt-3">체크아웃 대기중</p> : null}
                                {order.gifted || ["완료", "노쇼"].includes(order.state) ? <p className="text-muted pt-3">사용불가</p> : null}
                              </div>
                              <div
                                className="d-flex py-3 px-2"
                                onClick={() => {
                                  if (!order.gifted && !["입장", "퇴장", "외출", "완료", "노쇼"].includes(order.state)) {
                                    setIsCancel(false);
                                    setSelectOrder(order);
                                    setPurchaseRecordModal(!purchaseRecordModal);
                                  }
                                  // else if (!order.gifted && ["입장"].includes(order.state)) {
                                  //   setIsCancel(false);
                                  //   setSelectOrder(order);
                                  //   setCheckoutModal(true);
                                  // }
                                }}
                              >
                                {/* <div className="avatar-md mx-2">
                                                <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                                    <img src={item.image} alt="" className="img-fluid" />
                                                </div>
                                            </div> */}
                                <div className="mt-1" style={{ width: "100%" }}>
                                  <div className="card-text border-bottom py-2" style={{ width: "100%" }}>
                                    <FiCalendar size={18} color={"#1d1d1d"} />
                                    <span style={{ marginLeft: "10px", lineHeight: "32px" }}>{order.reservationDate} </span>
                                  </div>
                                  <div className="card-text">
                                    <FaRegUser size={16} color={"#1d1d1d"} />
                                    <span style={{ marginLeft: "10px", lineHeight: "32px" }}>{order.spot}번</span>
                                  </div>
                                  {order.options.length ? (
                                    <div className="card-text border-top py-3">
                                      <span style={{ fontWeight: "600" }}>추가 옵션</span>
                                      <br />
                                      <br />
                                      {order.options.map((item, idx, obj) => {
                                        return (
                                          <div key={item.id}>
                                            <div className="border-bottom py-2" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                              {item.name}
                                              <span style={{ textAlign: "right" }}>{item.price.toLocaleString()}원</span>
                                              {/* {idx !== obj.length - 1 ? ", " : ""} */}
                                            </div>
                                            {/* {idx !== obj.length - 1 ? <br /> : ""} */}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              {!order.gifted && !["입장", "퇴장", "외출", "완료", "노쇼"].includes(order.state) ? (
                                <div className="d-flex w-100 pb-3" style={{ justifyContent: "flex-end" }}>
                                  <div className="d-flex gap-2 pb-2" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                    <Button
                                      style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
                                      onClick={(e) => {
                                        navigate(`/add-options-detail/${order.id}`);
                                      }}
                                    >
                                      옵션추가
                                    </Button>

                                    <Button
                                      color="danger"
                                      style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f", display: order.options.length ? "block" : "none" }}
                                      onClick={(e) => {
                                        navigate(`/cancel-options-detail/${order.id}`);
                                      }}
                                    >
                                      옵션취소
                                    </Button>
                                  </div>

                                  <div className="d-flex gap-2 pb-2" style={{ justifyContent: "flex-end" }}>
                                    {/* <Button
                        style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
                        onClick={(e) => {
                          setIsCancel(false);
                          setSelectOrder(order);
                          setGiftModal(true);
                        }}
                      >
                        선물하기
                      </Button> */}
                                    <Button
                                      style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f" }}
                                      onClick={(e) => {
                                        setIsCancel(true);
                                        setSelectOrder(order);
                                        setPurchaseRecordModal(true);
                                      }}
                                    >
                                      예약취소
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex w-100 align-items-center pb-3 border-top pt-3 px-2 justify-content-between">
                                  {order.gift && order.gift["username"] ? (
                                    <div className="card-text">
                                      <IoGiftOutline size={18} color="#1d1d1d" style={{ marginRight: "5px" }} /> {order.gift["username"]}
                                    </div>
                                  ) : null}
                                  {!!order.gifted ? <span style={{ fontFamily: "Gmarket-M", color: "#ff5a5f" }}>선물완료</span> : null}
                                  {/* <span style={{ margin: "8px" }}></span> */}
                                  {order.gifted === "선물발신" ? (
                                    <Button
                                      color="danger"
                                      onClick={(e) => {
                                        handelCancelGift(order.id);
                                      }}
                                    >
                                      선물취소
                                    </Button>
                                  ) : null}
                                </div>
                              )}
                            </Item>
                          </Col>
                        ))}
                      </React.Fragment>
                    ))}
                  </>
                ) : null}

              </Col>
            </Row>

            {isLoading || isFetchingNextPage ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: 150 }}>
                <Spinner animation="border" variant="primary" />
              </div>
            ) : null}

            {isSuccess && data.pages.length === 0 ? (
              <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 150 }}>
                예약내역 없음
              </div>
            ) : null}

            <div className="d-flex justify-content-center gap-3" style={{ alignItems: "center", height: 50 }}>
              {hasNextPage ? (
                <Button color="light" onClick={() => fetchNextPage()}>
                  다음
                </Button>
              ) : null}
            </div>

          </Container>
        </div>
      </div>

      <PurchaseRecordModal
        isCancel={isCancel}
        setVisible={setPurchaseRecordModal}
        visible={purchaseRecordModal}
        info={selectOrder}
        setInfo={setSelectOrder}
        navigate={navigate}
        needRefresh={refetch}
      />
      <ReservationGiftModal setVisible={setGiftModal} visible={giftModal} info={selectOrder} setInfo={setSelectOrder} navigate={navigate} needRefresh={refetch} />
      <ReservationCheckoutModal setVisible={setCheckoutModal} visible={checkoutModal} info={selectOrder} setInfo={setSelectOrder} navigate={navigate} needRefresh={refetch} />
    </React.Fragment>
  );
}

export default ReservationList;
