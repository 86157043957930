import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionItem, Card, CardBody, Col, Collapse, Row, Button, Modal, ModalBody, ModalHeader, Container } from "reactstrap";

import _default from "flatpickr/dist/l10n/ko.js";
import "flatpickr/dist/themes/material_orange.css";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { ColorRing, Oval } from "react-loader-spinner";

import { API } from "../../api";
import { useUserStore } from "../../store/store";
import { getAccessToken } from "../../api/auth";
import campIcon from "../../assets/images/camp_icon.png";
import { SUBDOMAIN } from "../../assets/meta/meta";

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const today = new Date();

const android_link = "https://play.google.com/store/apps/details?id=com.yargeu.AmazingCamp&hl=ko-KR";
const ios_link = "https://apps.apple.com/us/app/amzingticket/id6447417241";

const Calender = ({
  membership_product_id = "",
  package_api = "/package/items/",
  skip_to_order = false,
}) => {
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState("");
  const [packages, setPackages] = useState([]);
  const [toggleId, setToggleId] = useState(-1);
  const [selectedSpotId, setSelectedSpotId] = useState([]);
  const [selectedWaitingSpotName, setSelectedWaitingSpotName] = useState("");

  const [waitingModalVisible, setWaitingModalVisible] = useState(false);
  const [user] = useUserStore((state) => [state.user]);

  // 오늘 날짜가 속한 주의 토요일을 계산하는 함수
  const getNextSaturday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (일요일) ~ 6 (토요일)
    const daysUntilSaturday = (6 - dayOfWeek + 7) % 7; // 다음 토요일까지 남은 일 수
    const nextSaturday = new Date(today);
    nextSaturday.setDate(today.getDate() + daysUntilSaturday);
    return nextSaturday;
  };

  const handleDate = (date) => {
    let parsedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setSelectedDate(parsedDate);
    return parsedDate;
  };

  // const requestPackageProducts = async (e) => {
  //   try {
  //     // if (e.length < 2) {
  //     //     return;
  //     // }
  //     // parsedDate가 selectedDate에 할당되기 전에 fetch 요청이 가서 일단 이렇게 함.
  //     let parsedDate = handleDate(e[0]);

  //     // axios로 변경
  //     const response = await API.get(`/package/items/?date=${parsedDate}`);
  //     const data = response.data;

  //     setPackages(data);
  //     setToggleId(-1);
  //     setSelectedSpotId([]);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const requestPackageProducts = async (e) => {
    try {
      let parsedDate = handleDate(e[0]);
      const response = await API.get(`${package_api}?date=${parsedDate}&subdomain=${SUBDOMAIN}${membership_product_id ? `&membership_product_id=${membership_product_id}` : ""}`);
      const data = response.data;

      setPackages(data);
      setToggleId(-1);
      setSelectedSpotId([]);
    } catch (error) {
      console.error(error);
    }
  };

  const requestWaiting = () => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      alert("로그인이 필요한 서비스 입니다.");
      navigate(`/login`);
      return;
    }

    const url = `/package/waiting-reservation/`;

    API.post(
      url,
      {
        date: selectedDate,
        packageId: toggleId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => response.data)
      .then((data) => {
        if (data.result === "ok") alert("선택한 예약에 대한 알람설정이 완료 되었습니다.");
        else if (data.result === "already waiting") alert("이미 알람설정을 한 예약건 입니다.");
        else alert("알람설정을 실패하였습니다.");
      })
      .catch((error) => console.log(error));

    setSelectedWaitingSpotName("");
    setWaitingModalVisible(false);
  };

  const getDetailPage = () => {
    if (skip_to_order) {
      const _package = packages.find((x) => x.id === toggleId);

      navigate(`/order`, {
        state: {
          type: 0, // "package"
          product: _package,
          selectDate: selectedDate,
          spotId: selectedSpotId,
          options: [],
          quantity: 1, // 나중에 티켓 수량 선택 시 이용할 필드
          membership_product_id: membership_product_id,
        },
      });
      return;
    }

    navigate(`/package/${toggleId}?date=${selectedDate}`, {
    // navigate(`/package/${toggleId}`, {
      state: {
        // selectedDate,
        selectedSpotId,
      },
    });
  };

  const isSpotSelected = (spot) => {
    return selectedSpotId.filter((ele) => ele.id === spot.id).length === 1;
  };

  const isWaitState = () => {
    let selectedPackage = packages.find((x) => x.id === toggleId);
    if (toggleId !== -1 && selectedSpotId.length === 0 && selectedPackage.count <= selectedPackage.reserved) return true;
    else return false;
  };

  useEffect(() => {
    // 컴포넌트가 처음 렌더링될 때 기본 날짜를 설정하고 API 요청을 보냅니다.
    const defaultDate = getNextSaturday();
    handleDate(defaultDate);
    requestPackageProducts([defaultDate]);
  }, []);

  return (
    <Container className="justify-content-center" style={{ minHeight: "100vh" }}>
      <Row className="justify-content-center pb-5">
        <Col lg={11} sm={10}>
          <div>
            <Flatpickr
              onChange={requestPackageProducts}
              className="form-control fs-17"
              options={{
                locale: _default.ko,
                inline: "true",
                mode: "single",
                dateFormat: "Y-m-d",
                minDate: "today",
                defaultDate: getNextSaturday(),
              }}
            />
          </div>

          {/* package product list */}
          <div className="mt-5 bg-white rounded">
            <div className="live-preview py-3 pb-5">
              <Accordion id="default-accordion-example" className="accordion-danger" open="" toggle={() => {}} flush>
                {/* {packages.map((item) => {return <span key={item.id}>{item.id}</span>})} */}
                {packages.length !== 0 ? (
                  packages.map((item) => {
                    return (
                      <AccordionItem key={item.id}>
                        <div className="accordion-header" id={`headingOne${item.id}`}>
                          <button
                            className={classnames("accordion-button", { collapsed: item.id === toggleId })}
                            type="button"
                            onClick={() => {
                              setToggleId(item.id === toggleId ? -1 : item.id);
                              setSelectedSpotId([]);
                            }}
                            style={{
                              cursor: "pointer",
                              fontWeight: item.id === toggleId ? "bold" : "normal",
                              fontFamily: "Gmarket-M",
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                            }}
                            // disabled={item.count <= item.reserved}
                          >
                            {item.images.length !== 0 ? (
                              <img src={item.images[0]} style={{ width: "70px", height: "70px", objectFit: "cover", borderRadius: "10px", marginRight: "20px" }} alt="thumnail" />
                            ) : (
                              <img src={campIcon} style={{ width: "70px", height: "70px", objectFit: "contain", borderRadius: "10px", marginRight: "20px" }} alt="thumnail" />
                            )}

                            {item.count <= item.reserved || item.price === 0 ? (
                              <span style={{ color: "#f7ccc3" }}>
                                {`${item.name}(${item.reserved}/${item.count})`} {item.spots?.length && item.spots[0].reason === "close" ? "예약불가" : "매진(대기가능)"}
                              </span>
                            ) : (
                              <>
                                {user && user.membership !== "" && item.membership_price && item.membership_price && item.membership_price != item.price ? (
                                  <span>
                                    {item.name}({item.reserved}/{item.count})&nbsp; <strike style={{ color: "#6d6d6d" }}>{numberWithCommas(item.price)}원</strike> &nbsp;{" "}
                                    <span style={{ color: "#ff5a5f" }}>멤버십 가격 {numberWithCommas(item.membership_price)}원 </span>
                                  </span>
                                ) : (
                                  <span style={{ lineHeight: "24px" }}>
                                    {item.name} ({item.reserved}/{item.count})
                                    <br />
                                    {numberWithCommas(item.price)}원
                                  </span>
                                )}
                              </>
                            )}
                          </button>
                        </div>

                        <Collapse isOpen={item.id === toggleId} className="accordion-collapse" id="collapseOne">
                          {/* <div>ID: {item.id} toggle: {toggleId}</div> */}
                          <div className="accordion-body">
                            <Card>
                              <CardBody>
                                <div className="live-preview" style={{}}>
                                  <div style={{ margin: "0 -36px" }}>
                                    {/* <div style={{ width: window.innerWidth < 450 ? "calc(100vw - 4px)" : "100%", marginLeft: window.innerWidth < 450 ? "-60px" : "0", overflowX: "hidden" }}> */}
                                    {/* <div style={{ width: window.innerWidth < 1000 ? "140%" : "120%", marginLeft: window.innerWidth < 1000 ? "-20%" : "-10%" }}> */}
                                    <img src={item["seat_img"]} style={{ resizeMode: "contain", width: "100%" }} className="img-fluid" alt="" />
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                            <div className="text-center" style={{ fontFamily: "Gmarket-M", marginBottom: "15px" }}>
                              자리를 선택해주세요
                            </div>
                            <div className="d-flex flex-wrap justify-content-between" style={{ position: "relative" }}>
                              {item.spots.map((spot) => {
                                return (
                                  <Button
                                    key={spot.id}
                                    className="btn-icon m-1"
                                    color={isSpotSelected(spot) ? "danger" : "light"}
                                    onClick={() => {
                                      if (!isSpotSelected(spot)) {
                                        setSelectedSpotId([...selectedSpotId, { id: spot.id, name: spot.name }]);
                                      } else setSelectedSpotId(selectedSpotId.filter((ele) => ele.id !== spot.id));
                                    }}
                                    disabled={!spot["is_able"] || item.price === 0}
                                  >
                                    {spot["is_able"] && item.price !== 0 ? (
                                      <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${spot.name}`}</div>
                                    ) : (
                                      <span style={{ color: "red" }}>x</span>
                                    )}
                                  </Button>
                                );
                              })}
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
                                return <Button key={i} style={{ zIndex: -9999 }} className="btn-icon m-1" disabled></Button>;
                              })}
                            </div>
                          </div>
                        </Collapse>
                      </AccordionItem>
                    );
                  })
                ) : (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Oval visible={true} height="50" width="50" color="#ff8a65" ariaLabel="oval-loading" secondaryColor="#fff" />
                  </div>
                )}
              </Accordion>
            </div>
          </div>
          <div style={{ height: 100, borderWidth: 2 }}></div>
        </Col>
      </Row>

      <Row
        className="position-fixed d-flex flex-row justify-content-center"
        // style={{ maxWidth: "620px", bottom: "100px", left: "calc(50vw + 6px)", width: "calc(100vw - 40px)", transform: "translate(-50%)", zIndex: 999 }}
        style={{ maxWidth: "620px", bottom: "100px", left: "50%", width: "95%", transform: "translate(-50%)", zIndex: 999, margin: "0 auto" }}
      >
        <Button
          style={{
            borderRadius: "12px",
            padding: "12px",
            fontFamily: "Gmarket-M",
            width: "100%",
            opacity: !isWaitState() && selectedSpotId.length === 0 ? 0.5 : 1,
            backgroundColor: "#ff5a5f",
            border: "none",
          }}
          className="fw-bold fs-15"
          size="lg"
          onClick={(e) => {
            e.nativeEvent.preventDefault();
            e.nativeEvent.stopPropagation();
            if (!isWaitState() && selectedSpotId.length === 0) return;

            if (toggleId !== -1 && selectedSpotId.length === 0) setWaitingModalVisible(true);
            else getDetailPage();
          }}
          // disabled={}
        >
          {isWaitState() ? `${packages.find((x) => x.id === toggleId).name}  ${selectedDate}  대기하기` : selectedSpotId.length === 0 ? "패키지를 선택해주세요" : "다음"}
        </Button>
      </Row>

      <Modal
        isOpen={waitingModalVisible}
        toggle={() => {
          setSelectedWaitingSpotName("");
          setWaitingModalVisible(!waitingModalVisible);
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader>
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              setSelectedWaitingSpotName("");
              setWaitingModalVisible(!waitingModalVisible);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <div className="mt-4">
            {/* <h5 className="modal-title m-2" id="staticBackdropLabel">예약이 취소되어 자리가 비었을 시 알람을 받겠습니까?</h5>
                        <hr></hr>
                        <h4 className="modal-title m-2" id="staticBackdropLabel">{packages.find(x => x.id === toggleId)?.name}</h4>
                        <h4 className="modal-title m-2" id="staticBackdropLabel">{selectedDate}</h4>
                        {/* <h4 className="modal-title m-2" id="staticBackdropLabel">{selectedWaitingSpotName}번 자리</h4> */}
            {/* <div className="hstack gap-2 justify-content-center">
                            <Button className="btn btn-success" onClick={requestWaiting}>알람받기</Button>
                        </div>  */}
            <h5 className="modal-title m-2" id="staticBackdropLabel">
              예약대기 기능은 앱으로만 가능합니다.
            </h5>
            <h5 className="modal-title m-2" id="staticBackdropLabel">
              스토어에서 어메이징티켓을 앱을 다운받아서 대기 기능을 이용해 보세요!
            </h5>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0", marginBottom: "20px" }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {!isIOS ? (
                  <a href={android_link}>
                    <img height="90px" alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
                  </a>
                ) : null}
                {!isAndroid ? (
                  <a href={ios_link}>
                    <img height="61px" alt="Download on the App Store" src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png" />
                  </a>
                ) : null}
              </div>
            </div>
            {/* <hr></hr>
                        <h4 className="modal-title m-2" id="staticBackdropLabel">{packages.find(x => x.id === toggleId)?.name}</h4>
                        <h4 className="modal-title m-2" id="staticBackdropLabel">{selectedDate}</h4> */}
            {/* <div className="hstack gap-2 justify-content-center">
                            <Button className="btn" onClick={() => setWaitingModalVisible(false)}>닫기</Button>
                        </div> */}
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default Calender;
