import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container, Form, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Label, Input, Table } from "reactstrap";
import classnames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";

import BgFrame from "../../../Components/Common/BgFrame";

import { clientKey, variantKey } from "../../../common/payments/toss/api";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";

import { SUBDOMAIN } from "../../../assets/meta/meta";
import { API, BACKEND_HOST } from "../../../api";

import CouponTicketSelectModal from "../../../Components/modal/CouponTicketSelectModal";
import CouponReservationSelectModal from "../../../Components/modal/CouponReservationSelectModal";
import { calcCouponSaleAmount } from "../../../common/utils/coupon_util";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { getAccessToken } from "../../../api/auth";
import SelectCardBox from "../../../Components/Common/SelectCardBox";
import { nicepayClientId } from "../../../common/payments/nicepay/keys";
import { requestPaymentNicePay } from "../../../common/payments/nicepay/api";
import { getHectoPayParams, requestPaymentHecto } from "../../../common/payments/hecto/api";
import { hectoClientKey } from "../../../common/payments/hecto/keys";

const OrderProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [user, setUser] = useState({
    phoneNum: null,
  });
  const [name, setName] = useState("");
  const [cancelRuleAgree, setCancelRuleAgree] = useState(false);
  const [purchaseAllAgree, setPurchaseAllAgree] = useState(false);
  const [purchaseOneAgree, setPurchaseOneAgree] = useState(false);
  const [tabList, setTabList] = useState([false, true]);

  const [couponModal, setCouponModal] = useState(false);
  const [selectCoupon, setSelectCoupon] = useState([]);
  const [targetSeat, setTargetSeat] = useState({});

  const PGModules = [];
  if (hectoClientKey) {
    PGModules.push({ id: 3, code: "hecto", label: "헥토페이" });
  } else if (nicepayClientId) {
    PGModules.push({ id: 2, code: "nicepay", label: "나이스페이" });
  } else if (clientKey) {
    PGModules.push({ id: 1, code: "toss", label: "토스" });
  }
  const [selectedPG, setSelectedPG] = useState(PGModules.length > 0 ? PGModules[0] : null);

  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const agreementWidgetRef = useRef(null);

  const handleTabClick = (index) => {
    setTabList((prevTabList) => {
      const newTabList = [...prevTabList];
      newTabList[index] = !newTabList[index]; // toggle 로직
      return newTabList;
    });
  };

  const toggleReservationDiscountCouponSelection = (product_id, coupon_id, spot_id, discount_amount, discount_type, min_purchase, discount_rate, max_discount = 0) => {
    setSelectCoupon((prev) => {
      let _prev = [...prev];
      const item = { coupon_id: coupon_id, product_id: product_id, spot_id: spot_id, discount_amount: discount_amount, discount_type, min_purchase, discount_rate, max_discount };
      const selected = _prev.find((item) => item.product_id === product_id && item.coupon_id === coupon_id && item.spot_id === spot_id);
      const blocked = _prev.find((item) => item.product_id === product_id && item.coupon_id === coupon_id && item.spot_id !== spot_id);

      if (blocked) {
        return _prev;
      } else {
        _prev = _prev.filter((item) => item.spot_id !== spot_id);
        if (!selected) {
          return [..._prev, item];
        } else {
          return _prev;
        }
      }
    });
  };

  const togglePurchaseAgreeOptions = (agree) => {
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };

  const optionsPrice = location.state.options
    .map((item) => {
      return parseInt(item.price);
    })
    .reduce((sum, currValue) => {
      return sum + currValue;
    }, 0);

  const totalOrderPrice = useMemo(() => {
    let _total = 0;

    if (selectCoupon.length > 0) {
      location.state.spotId.forEach((spot) => {
        const _coupon = selectCoupon.find((coupon) => coupon.spot_id === spot.id);
        const _price = location.state.product.price;
        const saleAmount = _coupon ? calcCouponSaleAmount(_coupon, _price) : 0;
        _total += _price - saleAmount;
      });
      _total += optionsPrice;
    } else {
      _total = location.state.product.price * (location.state.spotId.length) + optionsPrice;
    }

    return _total;
  }, [selectCoupon, location.state, optionsPrice]);

  const totalTaxfreePrice = location.state.options
    .map((item) => {
      if (item.taxfree) {
        return parseInt(item.price);
      } else {
        return 0;
      }
    })
    .reduce((sum, currValue) => {
      return sum + currValue;
    }, 0);

  useEffect(() => {
    (async () => {
      if (!user.phoneNum) return;
      if (paymentMethodsWidgetRef.current) return;

      const customerKey = `${user.phoneNum}_${user.id}@amazingpay`
      console.log("customerKey", customerKey)
      console.log("totalOrderPrice", totalOrderPrice)
      const paymentWidget = await loadPaymentWidget(clientKey, customerKey);
      if (paymentWidgetRef.current == null) {
        paymentWidgetRef.current = paymentWidget;
      }
      const paymentMethodsWidget = paymentWidgetRef.current.renderPaymentMethods(
        "#payment-method", { value: totalOrderPrice }, { variantKey: variantKey }
      );
      agreementWidgetRef.current = paymentWidgetRef.current.renderAgreement('#agreement', { variantKey: variantKey });
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, [user, totalOrderPrice]);

  useEffect(() => {
    if (paymentMethodsWidgetRef.current != null) {
      paymentMethodsWidgetRef.current.updateAmount(totalOrderPrice);
      console.log("updateAmount totalOrderPrice", totalOrderPrice)
    }
  }, [totalOrderPrice]);

  const processPayment = async () => {
    try {
      const accessToken = getAccessToken();

      if (!accessToken) {
        alert("로그인이 필요한 서비스입니다.");
        navigate(`/login`);
        return;
      }

      if (totalOrderPrice === 0) {
        alert("결제가능한 최소금액은 1,000원입니다.");
        return;
      }

      const response = await API.post(
        `package/pre-order/`,
        {
          spots: location.state.spotId,
          startDate: location.state.selectDate,
          options: location.state.options.map((item) => ({ spot_id: item.spotId, option_id: item.optionId })),
          coupons: selectCoupon.map((item) => ({ coupon_id: item.coupon_id, spot_id: item.spot_id })),
          membership_product_id: location.state.membership_product_id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      console.log("data >>> ", data);

      if ("error" in data) {
        alert(data.error);
        navigate(`/package/${location.state.product.id}?date=${location.state.selectDate}`, {
          state: {
            // selectedDate: location.state.selectDate,
            selectedSpotId: location.state.spotId,
          },
        });
        return;
      }

      if (selectedPG.code === "toss") {

        const paymentWidget = paymentWidgetRef.current;

        paymentWidget?.requestPayment({
          orderId: data.orderId,
          orderName: `${location.state.product.name}`,
          customerName: name,
          // customerEmail: "",
          customerMobilePhone: user.phoneNum,
          taxFreeAmount: totalTaxfreePrice,
          successUrl: `${BACKEND_HOST}/package/order/`,
          failUrl: `https://${SUBDOMAIN}.amazingticket.site/reservation`,
        });
      } else if (selectedPG.code === "nicepay") {
        requestPaymentNicePay({
          orderId: data.orderId,
          amount: totalOrderPrice,
          goodsName: `${location.state.product.name}`,
          method: 'card',
          returnUrl: '/package/order-nice/',
          errorCb: (result) => {
            console.log("errorCb", result);
            alert("결제에 실패하였습니다.");
            navigate(`/reservation`);
          }
        })
      } else if (selectedPG.code === "hecto") {
        const encryptedParams = await getHectoPayParams({
          orderId: data.orderId,
          amount: totalOrderPrice,
          phone: user.phoneNum,
          user_id: user.id,
          email: user.email,
          username: user.username,
        });

        requestPaymentHecto({
          ...encryptedParams,
          mchtName: "아메이징캠프",
          mchtEName: "AmazingCamp",
          pmtPrdtNm: `${location.state.product.name}`,
          notiUrl: `${BACKEND_HOST}/package/order-hecto/`,
          nextUrl: `${BACKEND_HOST}/package/order-done-hecto/`,
          cancUrl: `${BACKEND_HOST}/package/order-fail-hecto/`,
        });
      } else {
        alert("결제모듈이 설정되지 않았습니다.");
      }

    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response.data);
        alert(`결제에 실패하였습니다. ${error.response.data?.error || String(error.response.data)}`);
      } else {
        alert("지금은 구매가능 시간이 아니거나 품절된 상품입니다.");
      }
      navigate(`/reservation`);
    }
  };

  const logOut = () => {
    alert("허용되지 않은 접근입니다! 다시 로그인 해주세요.");
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = getAccessToken();

      if (accessToken) {
        try {
          let decoded = jwtDecode(accessToken);
          const userId = decoded["user_id"];

          const response = await API.get(`/accounts/users/${userId}/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = response.data;

          if (!data["전화번호"]) {
            logOut();
          } else {
            setUser({ id: userId, phoneNum: data["전화번호"], name: data["이름"] });
            setName(data["이름"]);
          }
        } catch (error) {
          console.log(error);
          logOut();
        }
      } else {
        setUser({
          phoneNum: null,
        });
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    console.log("location.state", location.state);
  }, [location.state]);

  return (
    <BgFrame>
      <React.Fragment>
        <div>
          <div>
            <Row>
              <Col xl={12}>
                <div>
                  <div>
                    <div>
                      <div
                        style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                        onClick={() => handleTabClick(0)}
                      >
                        <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                          주문자
                        </h5>
                        {tabList[0] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                      </div>
                      <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[0] ? "block" : "none" }}>
                        <Row className="gy-3">
                          <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>{name === "" ? "로그인 후 구매가능합니다." : name}</p>
                          <p style={{ margin: 0, fontFamily: "Gmarket-M" }}>{user.phoneNum ? user.phoneNum.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3") : ""}</p>
                        </Row>
                      </div>
                    </div>

                    <div style={{ width: "100%", height: "10px" }}></div>

                    <div>
                      <div
                        style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                        onClick={() => handleTabClick(1)}
                      >
                        <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                          주문상품
                        </h5>
                        {tabList[1] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                      </div>
                      <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[1] ? "block" : "none" }}>
                        <Row className="gy-3">
                          <Col lg={12} sm={12}>
                            <div className="">
                              {/* <Input id="orderInfo01" name="orderInfo" type="radio" className="form-check-input" defaultChecked /> */}
                              <div className="" htmlFor="orderInfo01">
                                <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                  {location.state.product.name}
                                  <span className="mb-2 text-uppercase" style={{ marginLeft: "5px" }}>
                                    ({location.state.product.site})
                                  </span>
                                </span>

                                <span className="fw-normal text-wrap mt-1 mb-1 d-block">{location.state.selectDate !== null ? `예약일 ${location.state.selectDate}` : null}</span>

                                {location.state.spotId !== null ? (
                                  <span className="fw-normal text-wrap my-3 d-block">
                                    <span className="fw-normal text-wrap mb-1 d-block fw-semibold">좌석 및 옵션</span>
                                    {location.state.spotId.map((spot, idx, obj) => {
                                      const _options = location.state.options.filter((value) => value.spotId === spot.id);
                                      return (
                                        <div key={idx} className="fw-normal text-wrap mb-1 mx-1" style={{ borderBottom: "1px solid #ededed", padding: "10px 0" }}>
                                          <div>
                                            <span>
                                              <span style={{ fontFamily: "Gmarket-M", color: "#f6475f" }}>{spot.name}</span>번 {location.state.product.price.toLocaleString()}원
                                            </span>
                                          </div>
                                          <div className="d-block" style={{ textAlign: "right" }}>
                                            {_options.map((option, oidx, oobj) => {
                                              return (
                                                <p key={oidx} className="text-muted mb-0 fs-14 mb-1">
                                                  {/* {oidx === 0 ? "- " : ""} */}
                                                  {option.name}
                                                  <span style={{ marginLeft: "5px" }}>
                                                    {option.price.toLocaleString()}원{/* {oidx !== oobj.length - 1 ? ", " : ""} */}
                                                  </span>
                                                </p>
                                              );
                                            })}
                                            <p
                                              className="fw-bold mt-2 btn btn-primary"
                                              onClick={() => {
                                                setCouponModal(true);

                                                setTargetSeat({
                                                  spot_id: spot.id,
                                                  product_id: location.state.product.id,
                                                  price: location.state.product.price,
                                                  options: _options,
                                                  reserve_date: location.state.selectDate,
                                                });
                                              }}
                                            >
                                              쿠폰선택하기
                                            </p>
                                            {selectCoupon && selectCoupon.length > 0
                                              ? selectCoupon
                                                .filter((coupons) => coupons.product_id === location.state.product.id && coupons.spot_id === spot.id)
                                                .map((coupenSelected) => {
                                                  return (
                                                    <div key={coupenSelected.id} className="fw-normal text-wrap mb-1 mx-1" style={{ padding: "10px 0" }}>
                                                      {coupenSelected ? (
                                                        <>
                                                          <span>쿠폰 적용 </span>
                                                          <span className="d-block" style={{ textAlign: "right", fontWeight: 600 }}>
                                                            {calcCouponSaleAmount(coupenSelected, location.state.product.price * location.state.quantity).toLocaleString()}원 할인
                                                          </span>
                                                        </>
                                                      ) : null}
                                                    </div>
                                                  );
                                                })
                                              : null}
                                          </div>
                                          {/* {idx !== obj.length - 1 ? ", " : ""} */}
                                        </div>
                                      );
                                    })}
                                  </span>
                                ) : null}
                                <p style={{ textAlign: "right", marginTop: "10px" }}>
                                  총 금액 <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>{` ${totalOrderPrice.toLocaleString()}원`}</span>
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div style={{ width: "100%", height: "10px" }}></div>

                    <div>
                      <div
                        style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                      >
                        <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                          취소 및 환불 규정
                        </h5>
                      </div>
                      <div style={{ border: "1px solid #e9ebec", padding: "20px 20px" }}>
                        <Row className="gy-3">
                          <Col sm={12}>
                            <div className="table-responsive">
                              <Table className="table-bordered table-nowrap align-middle mb-0">
                                <tbody>
                                  <tr>
                                    <td className="fw-medium">이용 3일 전까지</td>
                                    <td>취소수수료없음</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-medium">이용 2일 전</td>
                                    <td>총결제금액의 30% 차감</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-medium">이용 1일 전</td>
                                    <td>총결제금액의 50% 차감</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-medium">이용 당일</td>
                                    <td>환불불가능</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>

                        <Col sm={12} className="py-3">
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="cancelRuleAgree"
                              checked={cancelRuleAgree}
                              onChange={(e) => {
                                setCancelRuleAgree(e.target.checked);
                              }}
                            />
                            <label className="form-check-label" htmlFor="cancelRuleAgree">
                              환불 규정에 동의하기
                            </label>
                          </div>
                        </Col>

                        <Col sm={12} className="">
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="purchaseOneAgree"
                              checked={purchaseOneAgree}
                              onChange={(e) => {
                                togglePurchaseAgreeOptions(e.target.checked);
                              }}
                            />
                            <label className="form-check-label" htmlFor="purchaseOneAgree">
                              구매조건 확인 및 결제진행에 동의
                            </label>
                          </div>
                        </Col>
                      </div>
                    </div>


                    {PGModules.length > 1 ? <div className="max-w-540 w-100 mt-2">
                      <SelectCardBox
                        items={PGModules}
                        selectedItems={[selectedPG]}
                        onChange={(item) => {
                          setSelectedPG(item);
                        }}
                        isMulti={false}
                      />
                    </div> : null}

                    <div className={"max-w-540 w-100" + (selectedPG && selectedPG.code === "toss" ? " d-block" : " d-none")}>
                      <div id="payment-method" className="w-100" />
                      <div id="agreement" className="w-100" />
                    </div>

                    <div className="d-f lex align-items-start gap-3 mt-4 jus pb-3">
                      <button
                        type="button"
                        className="btn ms-auto nexttab"
                        style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M", margin: "0 auto", width: "100%", padding: "12px 0" }}
                        onClick={() => {
                          if (!cancelRuleAgree || !purchaseAllAgree) {
                            alert("동의항목을 확인해주세요.");
                            return;
                          }
                          processPayment();
                        }}
                      >
                        결제하기
                        <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2" style={{ backgroundColor: "#f6475f", color: "#fff", marginLeft: "10px" }}></i>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <CouponReservationSelectModal
          setVisible={setCouponModal}
          visible={couponModal}
          seats={targetSeat}
          selectCoupon={selectCoupon}
          setSelectCoupon={setSelectCoupon}
          toggleReservationDiscountCouponSelection={toggleReservationDiscountCouponSelection}
          navigate={navigate}
        />
      </React.Fragment>
    </BgFrame>
  );
};

export default OrderProcess;
